.pfp-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .pfp-modal-content {
    background-color: #181a20;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    color: #eaecef;

    h2 {
      margin-top: 0;
    }

    .pfp-categories {
      margin-top: 10px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      justify-content: center;

      button {
        margin: 5px 5px;
        padding: 8px 16px;
        font-size: 14px;
        background-color: transparent;
        border: 1px solid #ccc;
        border-radius: 4px;
        cursor: pointer;
        color: #eaecef;

        &:hover {
          background-color: #f0f0f0;
        }
      }
    }

    .pfp-options {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      img {
        margin: 10px;
        width: 100px;
        height: 100px;
        object-fit: cover;
        border-radius: 8px;
        cursor: pointer;
        transition: transform 0.3s ease, filter 0.3s ease;

        &:hover {
          transform: scale(1.1);
          filter: brightness(100%);
        }

        &:not(:hover) {
          filter: brightness(70%);
        }
      }
    }

    button {
      margin-top: 20px;
      padding: 10px 20px;
      font-size: 16px;
      background-color: #007bff;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #0056b3;
      }
    }
  }
}



.modal-change-info {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* Darkened background */
  display: flex;
  justify-content: center;
  align-items: center;

  .change-info-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 450px;
    background-color: #181a20;
    color: #eaecef;
    padding: 20px;
    border-radius: 15px;
    position: relative;
    z-index: auto;

    svg {
      color: #969ba5;
      height: 40px;
      width: 40px;
    }

    .change-banner {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .banner-change {
        display: flex;
        align-items: center;
        position: absolute;
        z-index: auto;
        color: whitesmoke;
        -webkit-text-stroke: 0.5px black;
        font-size: 25px;
      }

      img {
        width: 80%;
        height: 80%;
        transition: transform 0.5s ease-in-out;
        user-select: none;
        border-radius: 5px;
      }

      .banner-arrows {
        cursor: pointer;
        color: gray;
        font-size: 36px;
        transition: color 0.3s ease-in-out;
        &:hover {
          color: #312e2e;
        }
      }
    }

    .change-pfp {
      position: relative;
      display: inline-block;
      cursor: pointer;

      img {
        width: 105px;
        height: 105px;
        padding: 10px;
        border-radius: 50%;
      }

      .pen-edit {
        display: flex;
        position: absolute;
        background-color: whitesmoke;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        justify-content: center;
        align-items: center;
        bottom: 5px;
        right: 5px;

        .pen {
          padding: 6px;
          color: black;
        }
      }
    }

    .content-info-form__inputs {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
      width: 100%;

      label {
        font-weight: 500;
      }

      .content-info-form__input {
        display: flex;
        flex-direction: column;
        gap: 5px;
        width: 100%;

        input {
          height: 35px;
          width: 100%;
          background-color: #181a20;
          border-radius: 5px;
          padding: 0 10px;
          border: 1px solid #969ba5;
          transition: 0.2s;
          color: whitesmoke;

          &:focus {
            border: 1px solid #f0b90b;
          }
        }
      }
    }

    .content-info-form__btn {
      width: 100%;
      height: 35px;
      margin: 15px 0;
      border-radius: 8px;
      border: none;
      background-color: #f0b90b;
      color: #181a20;
      font-weight: 500;
      cursor: pointer;
    }
  }
}


.modal-change-info{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* Darkened background */
  display: flex;
  justify-content: center;
  align-items: center;

  .change-info-form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 450px;
    background-color: #181A20;
    color: #EAECEF;
    padding: 20px;
    border-radius: 15px;
    position: relative;
    z-index: auto;

    svg{
      color: #969ba5;
      height: 40px;
      width: 40px;
    }

    .change-banner{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .banner-change{
        display: flex;
        align-items: center;
        position: absolute;
        z-index: auto;
        color: whitesmoke;
        -webkit-text-stroke: 0.5px black;
      }

      img{
        width: 80%;
        height: 80%;
        transition: transform 0.5s ease-in-out;
        user-select: none;
        border-radius: 5px;
      }

      .banner-arrows {
        cursor: pointer;
        color: gray;
        font-size: 36px;
        transition: color 0.3s ease-in-out;
        &:hover {
          color: #312e2e;
        }
      }
    }

    .change-pfp{
      position: relative;
      display: inline-block;
      cursor: pointer;

      img{
        width: 105px;
        height: 105px;
        padding: 10px;
        border-radius: 50%;
      }

      .pen-edit{
        display: flex;
        position: absolute;
        background-color: whitesmoke;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        justify-content: center;
        align-items: center;
        bottom: 5px;
        right: 5px;

        .pen{
          padding: 6px;
          color: black;
        }
      }
    }

    .content-info-form__inputs{
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
      width: 100%;

      label{
        font-weight: 500;
      }

      .content-info-form__input{
        display: flex;
        flex-direction: column;
        gap: 5px;
        width: 100%;

        input{
          height: 35px;
          width: 100%;
          background-color: #181A20;
          border-radius: 5px;
          padding: 0 10px;
          border: 1px solid #969ba5;
          transition: 0.2s;
          color: whitesmoke;

          &:focus{
            border: 1px solid #F0B90B
          }
        }
      }
    }

    .content-info-form__btn{
      width: 100%;
      height: 35px;
      margin: 15px 0;
      border-radius: 8px;
      border: none;
      background-color: #F0B90B;
      color: #181A20;
      font-weight: 500;
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 426px){
  .pfp-modal {
    .pfp-modal-content {
      width: 95%;
      .pfp-categories {
        grid-template-columns: repeat(3, 1fr);
        button {
          padding: 6px 13px;
          font-size: 10px;}
      }
      .pfp-options {
        img {}
      }
      button {
        font-size: 13px;
      }
    }
  }

  .modal-change-info {

    .change-info-form {
      width: 90%;
      margin: auto;
      padding: 15px;

      svg {
        height: 30px;
        width: 30px;}
      .change-banner {
        img {
          width: 85%;
          height: auto;
        }
      }
      .change-pfp {
        img{
          width: 100px;
          height: 100px;
          padding: 15px;
          border-radius: 50%;
        }

        .pen-edit{
          width: 25px;
          height: 25px;
          bottom: 10px;
          right: 10px;

          .pen{
            padding: 6px;
            color: black;
          }
        }
      }
      .content-info-form__inputs {
        gap: 10px;
        width: 90%;
        label {
          font-weight: 300;
        }
        .content-info-form__input {
          width: 100%;
          gap: 1px;
        }
      }
      .content-info-form__btn {
        width: 70%;
        margin: 15px auto;
      }
    }
  }

}