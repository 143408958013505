.dataTable{
  color: white;

  .dataGrid{
    background: #11112b;
    color: white;
    font-size: 15px;
    font-family: Poppins, sans-serif;


    .MuiDataGrid-toolbarContainer{
      flex-direction: row-reverse;
    }

    //img{
    //  width: 32px;
    //  height: 32px;
    //  border-radius: 50%;
    //  object-fit: cover;
    //}
    p{
      color: white;
      font-family: Poppins, sans-serif;
      font-size: 18px;
    }

    button{
      color: white;
      font-family: "Poppins", sans-serif;
      font-weight: bold;
      font-size: 17px;
    }

    input{
      color: white;
      font-family: "Poppins", sans-serif;
      font-weight: bold;
      font-size: 17px;
    }

    svg path{
      color: white;
    }

    .action{
      display: flex;
      gap: 15px;

      img{
        width: 20px;
        height: 20px;
        cursor: pointer;
      }


    }
  }
}