.movie-search {
  position: relative;
  width: 100%;
  max-width: 450px;

  input {
    width: 100%;
    padding: 10px 25px;
    font-size: 12.5px;
    font-weight: 600;
  }

  button {
    position: absolute;
    right: 0;
    top: 2px;
    border: 4px;
    background-color: #ff0000;
    color: #fff;
    border-radius: 30px;
    padding: 8px 25px;
    font-size: 12.5px;
    font-weight: 600;
    box-shadow: 0px 0px 7px 8px rgba(255, 0, 0, 0.3019607843);
    transition: box-shadow 0.3s ease;
  }

  .search-result{
    position: fixed;
    top: 60px;
    width: 450px;
    border-radius: 15px;
    background: #2b2e38;
  }

  .src-items{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding: 15px;
    transition: .1s;

    .search_sugestion_line{
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 15px;
      font-size: 17px;
    }

    img{
      display: flex;
      justify-content: center;
      width: 50px;
      height: 60px;
    }

    &:hover{
      background: #25252f;
      border-radius: 15px;
    }
  }
}

@media only screen and (max-width: 1024px){
  .movie-search{
    width: 95%;

    input{
      width: 95%;
    }

    button{
      margin-right: 2.5%;
    }

    .search-result{
      width: 92%;
      top: 115px;
    }
  }
}