@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700,800,900');

.register-screen {
    font-family: 'Poppins', sans-serif;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: radial-gradient(at top, #010717 70%, #030F31 80%, #3A6CFF 100%);
    color: whitesmoke;
    flex-direction: column;

    .menu-return{
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 10px;
        font-size: 15px;
    }

    .register-logo{
        display: flex;
        align-items: center;
        padding: 15px;
        font-size: 40px;
        gap: 10px;
    }

    &__form {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 380px;
        padding: 1.5rem;
        box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
        background: linear-gradient(225deg, rgba(11, 30, 74, 1.0), rgba(1, 41, 55, 1.0));
        border-radius: 15px;
    }

    &__title {
        text-align: center;
        margin-bottom: 15px;
    }

    &__subtext {
        font-size: 0.8rem;
        display: block;
        margin: 0.5rem 0;
        color: whitesmoke;
    }

    .register-btn{
        display: flex;
        background-color: #4266BC;
        color: #CEDBF8;
        font-size: 18px;
        font-weight: 700;
        width: 50%;
        height: 40px;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        border: none;
        cursor: pointer;

        &:hover{
            background-color: #1E47AA;
        }
    }

    *{
        font-family: 'Poppins', sans-serif;
    }

    .form-group {
        color: whitesmoke;
    }
}

@media screen and (max-width: (480px)) {
    .register-screen {
        background: radial-gradient(at top, #010717 70%, #030F31 80%, #3A6CFF 100%);
        font-size: 12.5px;

        &__form {
            width: 85%;
        }

        &__title {
            font-size: 20px;
            margin-bottom: 5px;
        }

        .register-btn{
            font-size: 15px;
        }

        &__subtext {
            font-size: 12.5px;
        }

        .subtext-link{
            font-size: 12.5px;
        }
    }
}

@media (min-width: (480px)) and (max-width: (1024px)) {
    .register-screen {
        background: radial-gradient(at top, #010717 70%, #030F31 80%, #3A6CFF 100%);
        font-size: 17px;

        .menu-return{
            font-size: 25px;
        }

        &__form {
            width: 60%;
        }

        &__title {
            font-size: 30px;
            margin-bottom: 5px;
        }

        &__subtext {
            font-size: 17px;
        }

        .subtext-link{
            font-size: 17px;
        }

        .login-button{
            font-size: 20px;
            width: 130px;
            height: 50px;
        }
    }
}