.add-user-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    padding: 8px;
    gap: 8px;
    border: 2px solid #d7cfcf;
    border-radius: 8px;
    background-color: #1d1e26;
    color: #d7cfcf;
    cursor: pointer;
}

.add-user-btn:hover{
    background-color: #d7cfcf;
    color: #1d1e26;
    transition: 0.3s;
}