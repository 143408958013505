.error404 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: #ffffff;
  font-family: "Arial", sans-serif;
  text-align: center;

  .error-container {
    animation: fadeIn 0.8s ease-in-out;
    max-width: 600px;
    padding: 2rem;
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);

    h1 {
      font-size: 6rem;
      color: #f44336;
      margin: 0;
    }

    h2 {
      font-size: 2rem;
      color: #fdd835;
      margin-bottom: 1rem;
    }

    p {
      font-size: 1.2rem;
      color: #cfd8dc;
      margin-bottom: 2rem;
    }

    button {
      background: #f44336;
      border: none;
      color: #ffffff;
      padding: 0.8rem 2rem;
      font-size: 1rem;
      border-radius: 5px;
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        background: #c02115;
      }
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
