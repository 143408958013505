.chat {
  display: flex;
  flex-direction: column;
  justify-content: flex-end; /* changed from 'end' to 'flex-end' */
  height: 100vh;
  width: 100%;
  background: linear-gradient(45deg, #0F1522, #192030);
  background-size: cover;
  background-position: center bottom;
  position: absolute;
  overflow: hidden;
  top: 0;
}

.snow, .snow::after, .snow::before{
  pointer-events: none;
  position: absolute;
  right: 0;
  left: 0;
  top: -650px;
  bottom: 0;
  background-image: radial-gradient(4px 4px at 100px 50px, #fff, transparent),
  radial-gradient(6px 6px at 200px 150px, #fff, transparent),
  radial-gradient(3px 3px at 300px 350px, #fff, transparent),
  radial-gradient(4px 4px at 400px 350px, #fff, transparent),
  radial-gradient(6px 6px at 500px 100px, #fff, transparent),
  radial-gradient(3px 3px at 50px 200px, #fff, transparent),
  radial-gradient(4px 4px at 150px 300px, #fff, transparent),
  radial-gradient(6px 6px at 250px 400px, #fff, transparent),
  radial-gradient(3px 3px at 350px 500px, #fff, transparent);
  transform: translateY(0);
  background-size: 650px 650px;
  animation: snowAnim 3s linear infinite;
}

.snow::after{
  margin-left: -250px;
  opacity: 0.5;
  filter: blur(2px);
  animation-direction: reverse;
  animation-duration: 6s;
}

.snow::before{
  margin-left: -250px;
  opacity: 0.7;
  filter: blur(1px);
  animation-direction: reverse;
  animation-duration: 9s;
}

@keyframes snowAnim{
  from{
    transform: translateY(-450px);
  }
  to{
    transform: translateY(200px)
  }
}


.messages {
  z-index: 55;
  margin-bottom: 50px;
  margin-top: 80px;
  max-height: calc(100vh - 150px);
  overflow-y: auto;
  scrollbar-width: auto;
  scrollbar-color: #ff0000 rgba(27,27,39,1);

  .message {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
      margin-left: 25px;
      margin-bottom: 10px;
  }

  .name {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5em;
    margin-bottom: 5px;
    user-select: none;

    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }

    p {
      color: #A19D9B;
    }
  }

  .message-holder {
    max-width: 70%;

    p {
      display: flex;
      padding: 12px 17px;
      background-color: #22293E;
      border-radius: 20px;
      color: #f1f2f5;
    }
  }

  .message-my{
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    margin-right: 25px;
    margin-bottom: 10px;

    .name{
      display: flex;
      flex-direction: row-reverse;
    }

    .message-holder {

      p {
        display: flex;
        max-width: 100%;
        background-color: #3A7CDF;
      }
    }
  }
}

.message-input {
  display: flex;
  align-items: center; /* changed from 'end' to 'center' */
  justify-content: center;
  width: 100%;
  height: 100px;
  gap: 25px;
  background-color: #202636;
  padding: 25px;
  z-index: 1;
  position: relative;

  // &::before {
  //   content: '';
  //   position: absolute;
  //   top: -25px; /* Pomera sneg iznad inputa */
  //   left: 0;
  //   width: 100%;
  //   height: 25px; /* Visina snega */
  //   background-color: white;
  //   clip-path: polygon(
  //                   0% 100%,
  //                   10% 80%,
  //                   20% 90%,
  //                   30% 70%,
  //                   40% 85%,
  //                   50% 75%,
  //                   60% 85%,
  //                   70% 70%,
  //                   80% 90%,
  //                   90% 80%,
  //                   100% 100%
  //   );
  //   z-index: -1;
  // }

  input {
    background-color: #101420;
    border: none;
    width: 80%;
    height: 50px;
    border-radius: 25px;
    font-size: 15px;
    color: whitesmoke;
    padding: 10px 25px;
    transition: background-color 0.3s, border-color 0.3s;

    &:focus {
     background-color: #141826;
    } 
  }

  button {
    background-color: #101420;
    color: whitesmoke;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    font-size: 25px;
    cursor: pointer;
    transition: background-color 0.1s, transform 0.1s;

    &:hover{
      background-color: #272e41;
    }

    &:active {
      background-color: #1e2433;
      transform: scale(0.95);
    }

    &[disabled] {
      cursor: not-allowed;
      opacity: 0.5;
      background-color: #ccc;
    }

  }
}