@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700,800,900');

.resetpassword-screen {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: radial-gradient(at top, #010717 70%, #030F31 80%, #3A6CFF 100%);
    flex-direction: column;

    *{
        font-family: 'Poppins', sans-serif;
        color: whitesmoke;
    }

    .menu-return{
         display: flex;
         align-items: flex-start;
         justify-content: flex-start;
         gap: 10px;
         font-size: 15px;
    }

    .respass-logo{
        display: flex;
        align-items: center;
        padding: 15px;
        font-size: 40px;
        gap: 10px;
    }

    &__form {
        display: flex;
        width: 380px;
        padding: 1.5rem;
        box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
        align-items: center;
        flex-direction: column;
        background: linear-gradient(225deg, rgba(11, 30, 74, 1.0), rgba(1, 41, 55, 1.0));
        border-radius: 15px;
    }

    &__title {
        text-align: center;
        margin-bottom: 1rem;
    }

    .reset-pass__btn{
        display: flex;
        background-color: #4266BC;
        color: #CEDBF8;
        font-size: 18px;
        font-weight: 700;
        width: 80%;
        height: 45px;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        border: none;

        &:hover{
            background-color: #1E47AA;
        }
    }

    .disabled-btn{
        cursor: not-allowed;
    }
}

@media screen and (max-width: (480px)) {
    .resetpassword-screen {
        background: radial-gradient(at top, #010717 70%, #030F31 80%, #3A6CFF 100%);
        font-size: 12.5px;

        &__form {
            width: 85%;
        }

        &__title {
            font-size: 20px;
            margin-bottom: 5px;
        }
    }
}