@import "../../Styles/responsive.scss";

.ts-topBox {
  display: inline;

  h1{
    text-align: center;
  }

  .snow{
    display: none;
  }

  .list {

    .chat{
      max-width: 400px;
      min-width: 400px;
      max-height: 750px;
      min-height: 750px;
      border-radius: 15px;
      background: #0B0B15;
      box-shadow: #0B0B15 1px 0 3px;
      position: relative;

      .messages{
        margin-bottom: 0;
        margin-top: 10px;
      }

      .message-input{
        background: #07070c;

        input{
          background: #0B0B15;
        }

        &::before {
          display: none;
        }
      }
    }

    .listItem {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;
      gap: 10px;

      .topbox_user {
        display: flex;
        gap: 20px;

        img {
          width: 55px;
          height: 55px;
          border-radius: 50%;
          object-fit: cover;

          @include xxl{
            display: none;
          }
          @include lg{
            display: block;
          }
        }

        .userTexts {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 5px;

          .username_dashboard {
            font-size: 17px;
            font-weight: 500;
          }
          .email {
            font-size: 12px;

            @include xxl{
              display: none;
            }
            @include lg{
              display: block;
            }
          }
        }
      }

      .amount {
        font-weight: 500;
        font-size: 14px;
      }
    }
  }
}



