.add-user-form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 450px;
    background-color: white;
    color: #09090b;
    padding: 20px;
    border-radius: 15px;

}

.add-user-form svg{
    color: #969ba5;
    height: 40px;
    width: 40px;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); /* Darkened background */
    display: flex;
    justify-content: center;
    align-items: center;
}

.add-user-form__inputs{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    width: 100%;
}

.add-user-form__inputs-whole{
    margin-top: 20px;
    display: grid;
    gap: 20px;
    width: 100%;
}

.add-user-form__inputs label{
    font-weight: 500;
}

.add-user-form__input{
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
}

.add-user-form__input input{
    height: 35px;
    width: 100%;
    border-radius: 5px;
    padding: 0 10px;
    border: 1px solid #969ba5;
}

.add-user-form__btn{
    width: 100%;
    height: 35px;
    margin: 15px 0;
    border-radius: 8px;
    border: none;
    background-color: #18181b;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
}

/************************************ADDING USER MODAL ANIMATION********************************/
.loading-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
}

.loading-bg{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    color: #1d1e26;
    width: 450px;
    height: 250px;
    border-radius: 20px;
    border: 4px solid #386fd2;
    gap: 20px;
}

.loading-spinner {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #1d1e26;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
