.deleteacc-screen {
    color: whitesmoke;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: radial-gradient(at top, #010717 70%, #030F31 80%, #3A6CFF 100%);
    flex-direction: column;

    .delete-logo{
        display: flex;
        align-items: center;
        padding: 15px;
        font-size: 40px;
        gap: 10px;
    }

    &__form {
        display: flex;
        width: 450px;
        padding: 1.5rem;
        box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
        background: linear-gradient(225deg, rgba(11, 30, 74, 1.0), rgba(1, 41, 55, 1.0));
        color: white;
        flex-direction: column;
        align-items: center;
        border-radius: 15px;
    }

    &__title {
        text-align: center;
        margin-bottom: 1rem;
    }

    &__forgotpassword {
        font-size: 0.8rem;
    }

    &__subtext {
        font-size: 0.8rem;
        display: block;
        margin: 0.5rem 0;
    }

    *{
        font-family: 'Poppins', sans-serif;
    }
}

 .delete__btn{
     display: flex;
     background-color: #4266BC;
     color: #CEDBF8;
     font-size: 18px;
     font-weight: 700;
     width: 100%;
     height: 45px;
     align-items: center;
     justify-content: center;
     border-radius: 5px;
     border: none;

     &:hover{
         background-color: #1E47AA;
     }
 }

 @media screen and (max-width: (480px)) {
     .forgotpassword-screen {
         background: radial-gradient(at top, #010717 70%, #030F31 80%, #3A6CFF 100%);
         font-size: 12.5px;

         &__form {
             width: 85%;
         }

         &__title {
             font-size: 20px;
             margin-bottom: 5px;
         }

         &__subtext {
             padding: 7px;
             font-size: 12.5px;
         }

         .send-email__btn {
             font-size: 15px;
             font-weight: 650;
             width: 90%;
             height: 35px;
         }
     }
 }