.modal-overlay{
  z-index: auto;
}

.user-page {
  display: flex;
  flex-direction: row;
  background: #181A20;
  color: #EAECEF;

  .up-left{
    margin-top: 70px;
    display: flex;
    flex-direction: column;
    border-right: 3px solid #17171A;

    .up-banner{
      display: flex;
      width: 100%;
      position: relative;
    }

    .up-profpic{
      display: flex;
      position: absolute;
      z-index: auto;
      border: solid 2px whitesmoke;
      margin-top: 170px;
      margin-left: 50px;
      width: 100px;
      height: 100px;
      border-radius: 50%;
    }

    .up-pen{
      display: flex;
      font-size: 17px;
      position: absolute;
      z-index: 5;
      color: whitesmoke;
      margin-top: 25px;
      margin-left: 25px;
      cursor: pointer;
      transition: all 0.3s;
      border: whitesmoke solid 2px;
      border-radius: 50%;
      padding: 10px;

      &:hover{
        font-size: 22px;
      }
    }

    .up-section{
      margin-top: 45px;
      margin-left: 25px;
      margin-bottom: 25px;
    }
  }

  .up-right{
    display: flex;
    flex-direction: column;
  }

  .section {
    margin-top: 70px;
    margin-bottom: 20px;
  }

  .section:last-child {
    margin-bottom: 0;
  }

  h2 {
    font-size: 1.8rem;
    margin-bottom: 10px;
  }

  .info-box{
    border-radius: 10px;
    padding: 20px;
  }

  .anime-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border-radius: 10px;
    padding: 20px;

    img{
      min-width: 142px;
      min-height: 227px;
    }
  }

  .info-item {
    display: flex;
    margin-bottom: 10px;
  }

  .info-label {
    width: 100px;
    margin-right: 20px;
    color: #B7BDC6;
  }

  .info-value {
    background: #1E2026;
    padding: 13px;
    border-radius: 8px;
  }

  .anime-list {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      margin: 5px 0;
      padding: 8px 16px;
      background-color: #555;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #666;
      }
    }
  }

  .save-up{
    gap: 0.5rem;
    margin-right: 1.5rem;
    width: 126px;

    .up-movie-card{
      position: relative;
      background-position: top;
      background-repeat: no-repeat;
      background-size: cover;
      padding-top: 160%;
      border-radius: 15px;
      width: 126px;
    }
  }

}

@media only screen and (min-width: 320px) and (max-width: 556px){
  .user-page {
    display: flex;
    flex-direction: column;

    .up-left{
      .up-banner{
        width: 100%;
      }

      .up-profpic{
        width: 75px;
        height: 75px;
        margin-top: 75px;
      }

      .up-section{
        margin-left: 15px;
        margin-bottom: -15px;
      }

      .up-pen{
        font-size: 14px;
        margin-top: 15px;
        margin-left: 15px;
        cursor: pointer;
        padding: 8px;

        &:hover{
          font-size: 20px;
        }
      }
    }

    h2{
      font-size: 1.5em;
    }

    .info-box{
      padding: 10px;
    }

    .info-value{
      margin-left: -15px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .anime-list{
      margin-bottom: 20px;
    }

    .save-up{
      width: 113px;

      .up-movie-card{
        width: 113px;
      }
    }
  }
}

@media only screen and (max-width: 320px) {
  .user-page{
    flex-direction: column;
    .up-left{

      .up-banner{
        width: 100%;
      }

      .up-profpic{
        width: 60px;
        height: 60px;
        margin-top: 60px;
      }

      .up-section{
        margin-left: 15px;
        margin-bottom: -15px;
      }

      .up-pen{
        font-size: 12px;
        margin-top: 15px;
        margin-left: 15px;
        cursor: pointer;
        padding: 6px;

        &:hover{
          font-size: 16px;
        }
      }
    }

    h2{
      font-size: 1.5em;
    }

    .info-value{
      margin-left: -15px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .save-up{
      width: 98px;

      .up-movie-card{
        width: 98px;
      }
    }

  }
}

    @media only screen and (min-width: 556px) and (max-width: 846px){
  .user-page {
    display: flex;
    flex-direction: column;

    .up-left{
      .up-banner{
        width: 100%;
      }

      .up-profpic{
        width: 85px;
        height: 85px;
        margin-top: 130px;
      }

      .up-section{
        margin-bottom: -15px;
      }
    }

    .info-box{
      padding: 10px;
    }

    .info-value{
      margin-left: -15px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .anime-list{
      margin-bottom: 20px;
    }
  }
}

@media only screen and (min-width: 846px) and (max-width: 1246px){
  .user-page{
    .up-left{
      .up-profpic{
        width: 85px;
        height: 85px;
        margin-top: 95px;
      }
    }
  }
}