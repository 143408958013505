// Define color variables
$text-color: #ffffff;
$bg-color: #0B0B15;
$box-bg-color: #0d0d17;
$hover-bg-color: #1a1a28;
$border-color: #FF3B3B;
$accent-color: #ee7b7b;

.subtitle-uploader {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 8px;
  font-family: Poppins, sans-serif;
  color: $text-color;
  max-width: 500px;
  margin: auto;
  background: $bg-color;

  h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: $accent-color;
    text-align: center;
  }

  // Drag-and-Drop Area
  .upload-box {
    width: 100%;
    border: 2px dashed $border-color;
    padding: 20px;
    text-align: center;
    border-radius: 8px;
    cursor: pointer;
    background: $box-bg-color;
    transition: 0.3s;

    &:hover {
      border-color: $accent-color;
      background: $hover-bg-color;
    }
  }

  // Input Fields and Dropdown Section
  .dropdowns {
    margin-top: 20px;
    padding: 15px;
    border: 2px solid $border-color;
    border-radius: 8px;
    background: $box-bg-color;

    display: flex;
    flex-direction: column;
    gap: 15px;

    .input-group {
      display: flex;
      flex-direction: column;

      label {
        font-size: 14px;
        margin-bottom: 5px;
        color: $accent-color;
      }

      input,
      select {
        width: 100%;
        padding: 10px;
        border: 1px solid $border-color;
        border-radius: 5px;
        color: $text-color;
        font-size: 14px;
        background: #13131e;
        transition: 0.3s;

        &:focus {
          outline: none;
          border-color: $accent-color;
          background: #1c1c2c;
        }

        &:hover {
          border-color: $accent-color;
        }
      }
    }
  }

  // Uploaded Files Section
  .uploaded-files {
    margin-top: 20px;
    padding: 15px;
    border-radius: 5px;
    border: 2px solid $border-color;
    background: $box-bg-color;
    max-height: 550px; // Set a maximum height for the container
    overflow-y: auto; // Enable vertical scrolling when content exceeds the height
//sfsaf
    h3 {
      font-size: 16px;
      margin-bottom: 10px;
      color: $accent-color;
    }

    .file-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      background: #2a2a3c;
      padding: 10px;
      border-radius: 5px;
      margin-bottom: 10px;

      .file-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .profile-picture {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          margin-right: 10px;
        }

        .uploader-info {
          flex-grow: 1;

          .uploader-name {
            font-size: 14px;
            font-weight: bold;
          }

          .upload-date {
            font-size: 12px;
            color: #bbb;
          }
        }

        .remove-button {
          background: transparent !important;
          font-size: 20px;
          border: none;
          color: #ff5e5e;
          cursor: pointer;

          &:hover {
            color: #ff8888;
          }
        }
      }

      .file-name {
        font-size: 14px;
        color: #ffffff;
      }
    }
  }


  .submit-button {
    margin-top: 20px;
    padding: 10px 15px;
    font-size: 16px;
    color: $text-color;
    background: $border-color;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;

    &:disabled {
      background: #555; // Disabled state color
      cursor: not-allowed;
    }

    &:hover:not(:disabled) {
      background: $accent-color;
    }
  }

}
.ftbu-title{
  padding: 15px;
}

.file-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
  background: #2a2a3c;
  border-radius: 5px;

  .file-info {
    display: flex;
    flex-direction: column;

    .file-name {
      font-size: 14px;
      color: #ffffff;
    }

    .file-status {
      font-size: 12px;
      color: #4caf50;
      margin-top: 4px;
    }
  }

  .file-actions {
    display: flex;
    gap: 10px;

    button {
      background: transparent;
      border: none;
      font-size: 20px;
      color: #ffffff;
      cursor: pointer;

      &:hover {
        color: #ff0000;
      }
    }
  }
}
