@use "../../scss/" as *;

.menu-item{
  padding-top: 12px;
}


.footer {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%), no-repeat center center;
  position: relative;
  padding: 3rem 2rem;
  background-position: top;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;

  h3{
    font-size: 18px;
    color: #B7BDC6;
  }

  h4{
    font-size: 15px;
    color: #B7BDC6;
  }

  .logo{
    display: flex;
    justify-content: center;
    align-items: center;

    .bf{
      display: flex;
      flex-direction: row;
      letter-spacing: -1.2px;

      .flix{
        color: red;
      }
    }
  }

  .soc-mda{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    gap: 10px;
  }

  &__content {
    max-width: 1000px;

    &__menus {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      //grid-template-columns: repeat(3, 1fr);
      gap: 40px;

      @include mobile {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    &__menu {
      @include flex(center, flex-start);
      flex-direction: column;
      font-size: 1.5rem;
      font-weight: 600;
    }
  }
}