// hero-slide-skeleton.scss
@keyframes colorTransition {
  0% {
    background-color: #1A1A20; // Initial color
  }
  50% {
    background-color: #2E2E38; // Intermediate color
  }
  100% {
    background-color: #1A1A20; // Final color, same as initial for smooth transition
  }
}

.hero-slide-skeleton {
  margin-top: 70px;
  width: 100%;
  height: 600px; // Adjust based on your actual hero size
  background-color: #1A1A20;
  border-radius: 8px;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px; // Adjust as necessary
}

.hero-image-skeleton {
  height: 60%;
  background-color: #24242B;
  border-radius: 4px;
  animation: colorTransition 2s infinite alternate;
}

.hero-skeleton-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center; // Center content vertically within the remaining space
}

.hero-title-skeleton,
.hero-subtitle-skeleton {
  background-color: #2E2E38;
  border-radius: 4px;
  height: 20px;
  margin: 10px 0;
  animation: colorTransition 2s infinite alternate-reverse;
}

.hero-title-skeleton {
  width: 70%;
  height: 40px; // More prominent height for title
}

.hero-subtitle-skeleton {
  width: 50%;
  height: 20px; // Standard height for subtitles
}
