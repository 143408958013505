.video-upload {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    .upload-container {
        max-width: 700px;
        width: 100%;
        padding: 1rem;

        .selecton{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            margin-top: 15px;

            select{
                padding: 15px 10px;
                border: 1px solid #4a5568;
                background: radial-gradient(circle, rgba(16,23,38,1) 0%, rgba(5,9,21,1) 100%);
                width: 48%;
                border-radius: 10px;
                font-weight: bold;
                color: white;

                option{
                    background: #050915FF;
                    font-weight: bold;
                    color: white;
                }
            }

            input{
                color: white;
                border-radius: 10px;
                padding: 10px 6px;
                font-weight: bold;
                border: 1px solid #4a5568;
                background: radial-gradient(circle, rgba(16,23,38,1) 0%, rgba(5,9,21,1) 100%);
                width: 48%;
            }
        }

        .drop-area {
            padding: 2rem;
            border: 2px dashed #4a5568;
            background: linear-gradient(
                            135deg,
                            #3B0864,
                            #33114F 50%,
                            #3B0864
            );
            border-radius: 0.5rem;
            text-align: center;
            transition: all 0.3s;
            &.dragging {
                border-color: #805ad5;
                background: rgba(66, 56, 126, 0.2);
            }
            .upload-icon {
                width: 50px;
                height: 50px;
                color: #C084FC;
            }
            .title {
                font-size: 1.5rem;
                color: #f7fafc;
            }
            .subtitle {
                font-size: 0.9rem;
                color: #a0aec0;
            }
        }

        .ep-stats{
            display: flex;
            flex-direction: row;
            gap: 5%;
            .file-list {
                width: 100%;
                margin-top: 1rem;
                .file-item {
                    display: flex;
                    align-items: center;
                    padding: 0.5rem;
                    border-radius: 0.5rem;
                    background: #060B17;
                    border: 1px solid #151925;
                    margin-bottom: 0.5rem;
                    font-size: 13.5px;
                    font-weight: inherit;

                    .done {
                        color: #38a169;
                    }
                    .failed {
                        color: #e53e3e;
                    }
                    .spinner {
                        width: 20px;
                        height: 20px;
                        border: 2px solid #805ad5;
                        border-top-color: transparent;
                        border-radius: 50%;
                        animation: spin 1s linear infinite;
                    }
                    .progress-bar {
                        flex: 1;
                        height: 6px;
                        background: #4a5568;
                        border-radius: 3px;
                        overflow: hidden;
                        margin: 0 0.5rem;
                        .progress-indicator {
                            height: 100%;
                            background: #805ad5;
                        }
                    }
                    .progress-text {
                        color: #a0aec0;
                    }
                    .file-name {
                        flex: 1;
                    }
                    .status-icon {
                        margin-left: 0.5rem;
                    }
                }
            }

            .status-card {
                width: 25%;
                height: 155px;
                margin-top: 1rem;
                background: radial-gradient(circle, rgba(16,23,38,1) 0%, rgba(5,9,21,1) 100%);
                border-radius: 0.5rem;
                padding: 1rem;
                .status-title {
                    color: #f7fafc;
                    font-size: 1.25rem;
                }
                .status-info {
                    margin-top: 0.5rem;
                    .encoding-text {
                        color: #a0aec0;
                    }
                    .encoding-progress {
                        display: flex;
                        align-items: center;
                        .encoding-percentage {
                            color: #f7fafc;
                            font-size: 1.5rem;
                            margin-right: 1rem;
                        }
                        .progress-bar {
                            flex: 1;
                            height: 6px;
                            background: #4a5568;
                            border-radius: 3px;
                            overflow: hidden;
                            .progress-indicator {
                                height: 100%;
                                background: #805ad5;
                            }
                        }
                    }
                }
            }
        }
    }
}
.discord-checkbox {
    margin-top: 15px;
    display: flex;
    align-items: center;

    .checkbox-container {
        display: flex;
        align-items: center;
        cursor: pointer;
        user-select: none;
        font-weight: bold;
        color: #f7fafc;
        position: relative;
    }

    .checkbox-container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .checkmark {
        position: relative;
        height: 20px;
        width: 20px;
        background-color: #4a5568;
        border-radius: 5px;
        margin-right: 10px;
        transition: background-color 0.3s;
    }

    .checkbox-container input:checked ~ .checkmark {
        background-color: #805ad5;
    }

    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    .checkbox-container input:checked ~ .checkmark:after {
        display: block;
    }

    .checkbox-container .checkmark:after {
        left: 7px;
        top: 3px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}
