.series-no-episodes-bg{
    /*background: linear-gradient(135deg, #000000, #18181b, #27272a);*/
    /*width: 100%;*/
    /*height: 100%;*/
}

.upper-info{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 35px;
}
.upper-info-left{
    background: rgba(24, 24, 27, 0.4);
    min-width: 45%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 40px;
    border-radius: 15px;
    gap: 30px;
}

.upper-info-right{
    background: rgba(24, 24, 27, 0.4);
    min-width: 45%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 40px;
    border-radius: 15px;
    gap: 30px;
}

.icon-tv-dash{
    border-radius: 50%;
    width: 50px;
    height: 50px;
    background-color: rgba(147, 51, 234, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #a78bfa;
}

.icon-users-dash{
    border-radius: 50%;
    width: 50px;
    height: 50px;
    background-color: rgba(219, 39, 119, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #f472b6;
}