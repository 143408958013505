.notification-screen{
    display: flex;
    flex-direction: column;
    border: 2px white solid;
    border-radius: 8px;
    gap: 15px;
    margin: 20px;
    padding: 20px 25px;
}

.notifications-header{
    font-weight: 600;
}

.notifications-subtitle{
    font-weight: 400;
    color: #c5c0c0;
}

.notification-labels{
    font-weight: 500;
    font-size: 15px;
    margin-bottom: 5px;
}

.notification-inputs{
    width: 100%;
    padding: 9px 12px;
    font-family: Poppins, sans-serif;
    font-weight: 500;
    color: #c5c0c0;
    border: 1px solid #c5c0c0;
    border-radius: 5px;
    background: transparent;
}

.notification-buttons{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;
}

.notification-clear{
    text-align: center;
    padding: 10px 14px;
    border-radius: 6px;
    background: transparent;
    border: 1px solid #c5c0c0;
    color: white;
    font-weight: 500;
    transition: 0.3s;
}

.notification-clear:hover{
    border-radius: 8px;
    background-color: #313133;
    transform: scale(98%);
}

.notification-send{
    text-align: center;
    padding: 10px 14px;
    border-radius: 6px;
    background: white;
    border: none;
    color: #06060f;
    font-weight: 500;
    transition: 0.3s;
}

.notification-send:hover{
    border-radius: 8px;
    background: #afacac;
    transform: scale(98%);
}

.notification-send:disabled{
    cursor: not-allowed;
}

.feedback-message {
    font-size: 1rem;
    margin-top: 1rem;
    padding: 0.75rem 1rem;
    border-radius: 4px;
    text-align: center;
}

.feedback-message.success {
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
}

.feedback-message.error {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
}