// Globalni stil za stranicu
.notes-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px;
}

// Kontejner za beleške
.notes-container {
  width: 100%;
  max-width: 620px;
  background: #0B0B15;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  text-align: center;

  h1 {
    color: #ff3b3b; // Crveni akcenti
    font-size: 24px;
    margin-bottom: 15px;
  }
}

// Dodavanje beleški
.add-note {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;

  input {
    flex: 1;
    padding: 10px;
    font-size: 16px;
    border: 2px solid #ff3b3b;
    border-radius: 8px;
    background: #141423;
    color: #fff;
    transition: border 0.3s;

    &:focus {
      border: 2px solid #ff6b6b;
      outline: none;
    }
  }

  button {
    background: #ff3b3b;
    color: white;
    border: none;
    font-size: 20px;
    padding: 10px 15px;
    border-radius: 8px;
    cursor: pointer;
    transition: background 0.3s;

    &:hover {
      background: #ff6b6b;
    }
  }
}

// Lista beleški
.notes-list {
  list-style: none;
  padding: 0;
  max-height: 300px;
  overflow-y: auto;

  li {
    display: flex;
    align-items: center;
    background: #141423;
    padding: 12px;
    margin-bottom: 8px;
    border-radius: 8px;
    font-size: 16px;
    color: #fff;
    text-align: left;

    .avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 10px;
    }

    .note-content {
      display: flex;
      flex-direction: column;

      .username {
        font-weight: bold;
        color: #ff6b6b;
        font-size: 16px;
      }

      p {
        margin: 5px 0 0;
        font-size: 16px;
        color: #fff;
      }

      .note-date {
        font-size: 12px;
        color: #bbb;
        margin-top: 5px;
      }
    }
  }
}