@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700,800,900');


.login-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    width: 100%;
    height: 100vh;
    background: radial-gradient(at top, #010717 70%, #030F31 80%, #3A6CFF 100%);
    color: whitesmoke;
    flex-direction: column;

    .menu-return{
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 10px;
        font-size: 15px;
    }

    .login-logo{
        display: flex;
        align-items: center;
        padding: 15px;
        font-size: 40px;
        gap: 10px;
    }

    &_form {
        display: flex;
        flex-direction: column;
        width: 380px;
        padding: 1.5rem;
        box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
        background: linear-gradient(225deg, rgba(11, 30, 74, 1.0), rgba(1, 41, 55, 1.0));
        align-items: center;
        border-radius: 15px;
    }

    &__title {
        text-align: center;
        margin-bottom: 1rem;
    }

    &__forgotpassword {
        font-size: 0.7rem;
    }

    &__subtext {
        font-size: 0.8rem;
        display: block;
        margin: 0.5rem 0;
        color: whitesmoke;
    }

    .login-button{
        background-color: #4266BC;
        border-radius: 5px;
        width: 100px;
        height: 35px;
        border: none;
        outline: none;
        font-weight: 700;
        color: #CEDBF8;
        cursor: pointer;
        
        &:hover{
            background-color: #1E47AA;
        }
    }

    *{
        font-family: 'Poppins', sans-serif;
        color: whitesmoke;
    }

    .login-message{
        display: flex;
        color: #D7F7FF;
        font-size: 12px;
        font-weight: lighter;
        font-family: 'Poppins', sans-serif;
        align-items: flex-start;
        text-align: left;
    }
}

.subtext-link{
    color: whitesmoke;
}

@media (max-width: (480px)) {
    .login-screen {
        background: radial-gradient(at top, #010717 70%, #030F31 80%, #3A6CFF 100%);
        font-size: 12.5px;

        .menu-return{
            font-size: 15px;
        }

        .login-logo{
            font-size: 35px;
        }

        .login-button{
            font-size: 15px;
        }

        &_form {
            width: 85%;
        }

        &__title {
            font-size: 20px;
            margin-bottom: 5px;
        }

        &__subtext {
            font-size: 12.5px;
        }

        .subtext-link{
            font-size: 12.5px;
        }
    }
}

@media (min-width: (480px)) and (max-width: (1024px)) {
    .login-screen {
        background: radial-gradient(at top, #010717 70%, #030F31 80%, #3A6CFF 100%);
        font-size: 17px;

        .menu-return{
            font-size: 25px;
        }

        .login-message{
            font-size: 17px;
        }

        &_form {
            width: 60%;
        }

        &__title {
            font-size: 30px;
            margin-bottom: 5px;
        }

        &__subtext {
            font-size: 17px;
        }

        .subtext-link{
            font-size: 17px;
        }

        .login-button{
            font-size: 20px;
            width: 130px;
            height: 50px;
        }
    }
}