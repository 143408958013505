@use "../../Mainscreen/scss/" as *;

.banner {
  height: 50vh;
  position: relative;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include overlay();
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
    background-image: linear-gradient(to top, $body-bg, rgba($black, 0));
  }
}

.movie-content {
  @include flex(flex-start, flex-start);
  max-width: 1260px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -200px;
  position: relative;
  padding: 0 2rem;

  &__poster {
    flex: 1;

    @include mobile {
      display: none;
    }

    &__img {
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: $border-radius;
      padding-top: 165%;
    }
  }

  &__info {
    width: 70%;
    padding-left: 2rem;
    position: relative;

    @include mobile {
      width: 100%;
      padding-left: 0;
    }

    & > * {
      margin-bottom: 2rem;
    }

    .tal{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;

      .title {
        display: flex;
        flex-direction: row;
        font-size: 4rem;
        line-height: 1;
      }

      .anime-logo{

        img{
          max-height: 75px;
          min-height: 75px;
          min-width: auto;
          object-fit: contain;
        }
      }
    }

    .genres {
      flex-wrap: wrap;

      & > * ~ * {
        margin-left: 0.5rem;
      }

      &__item {
        padding: 0.5rem 1.5rem;
        border: 2px solid $white;
        border-radius: $border-radius;
        font-size: 0.8rem;
        font-weight: 600;
        background-color: $body-bg;
      }
    }
  }

  .season-container {
    margin-top: 20px;
    background: #0B0E11;
    padding: 8px 15px;
    border-radius: 15px;
    margin-bottom: 20px;

    .season{
      margin-bottom: 15px;
    }

    .season-title{
      display:  flex;
      align-items: flex-start;
      flex-direction: column;
    }

    h2 {
      font-size: 1.8rem;
      margin-bottom: 10px;
      color: #B7BDC6;;
    }

    .season {
      text-align: center;
      display: flex;
      align-items: center;

      a {
        font-size: 1.6em;
        margin-left: 10px;
        color: white;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }

      img {
        max-width: 80px;
        border-radius: 10px;
        height: auto;
        margin-right: 10px;
      }
    }
  }
}

.casts {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
  gap: 10px;

  &__item {
    &__img {
      padding-top: 160px;
      background-size: cover;
      margin-bottom: 0.5rem;
    }

    &__name {
      font-size: 0.8rem;
    }
  }
}

.episode-list {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.episode-arrows {
  display: flex;

  button {
    background-color: #282c34;
    color: #61dafb;
    border: none;
    padding: 8px;
    margin: 0 5px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }

  button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  button:hover:not([disabled]) {
    background-color: #61dafb;
    color: #282c34;
  }
}


.video {
  margin-bottom: 3rem;

  &__title {
    margin-bottom: 1.5rem;
  }
}

/* details.scss */
.svg-container {
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  z-index: -1; // Postavlja SVG ispod ostalih elemenata
  opacity: 70%;
  pointer-events: none;
  transition: position 0.3s ease, top 0.3s ease; /* Dodaj animaciju za glatko premeštanje */
  filter: blur(4px) brightness(0.6); /* Dodaj blur i smanji osvetljenost za crnu boju */
  background: rgba(0, 0, 0, 0.3); /* Dodaj crnu boju preko SVG-a */
}

.svg-container.sticky {
  position: sticky;
  bottom: auto;
  top: 0;
}

@media only screen and (max-width: 1024px) {

  .movie-content{
    &__info{
      .tal{
        .anime-logo{
          display: none;
        }
      }
    }
  }

  .episode-list-container{
    width: 25 * 14px;
    padding: 13px;
  }

  .episode-list{
    justify-content: flex-start;
  }

  .svg-container svg{
    filter: blur(2px);
  }
}

@media only screen and (max-width: 480px) {
  .svg-container{
    display: none;
  }
    .title {
      font-size: 2rem !important;
    }
}

.title-info{
  display: flex;
  gap: 15px;
}
.title-info-header{
  display: flex;
  flex-direction: column;
  gap: 15px;
}