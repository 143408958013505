@import "./variables.scss";
@import "./responsive.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
}

.main {
  font-family: "Poppins", sans-serif;
  background-color: #060610;
  color: $main-color;
  min-height: 100vh;
}

.container {
  display: flex;
}

.menuContainer {
  width: 250px;
  padding: 5px 20px;
  border-right: 2px solid #0B0B15 ;

  @include lg{
    width: max-content;
  }
}

.contentContainer {
  padding: 5px 20px;
  width: 100%;
}