.terms {
  max-width: 800px;
  margin: 65px auto;
  padding: 2rem;
  background: #18181c;
  color: #ffffff;
  font-family: "Arial", sans-serif;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

  .language-switch {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1.5rem;

    button {
      background: none;
      border: 2px solid #ffffff;
      color: #ffffff;
      padding: 0.5rem 1rem;
      margin-left: 0.5rem;
      border-radius: 5px;
      cursor: pointer;
      transition: all 0.3s ease;

      &.active {
        background: #fd0f0f;
        border-color: #e30000;
      }

      &:hover {
        background: #770808;
      }
    }
  }

  h1 {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: #ff0000;
  }

  .last-updated {
    text-align: center;
    font-size: 0.9rem;
    color: #b0bec5;
    margin-bottom: 1.5rem;
  }

  .intro {
    text-align: center;
    font-size: 1.1rem;
    margin-bottom: 2rem;
    color: #eceff1;
  }

  .section {
    margin-bottom: 2rem;

    h2 {
      font-size: 1.5rem;
      color: #ff0000;
      margin-bottom: 0.5rem;
    }

    p {
      font-size: 1rem;
      color: #cfd8dc;
      line-height: 1.6;
    }
  }
}
