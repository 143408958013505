.add-notification-form{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 450px;
  //background-color: #181A20;
  color: #EAECEF;
  padding: 20px;
  border-radius: 15px;
  background-color: #09092b;
  border: 2px solid white;

}

.add-notification-form svg{
  color: #969ba5;
  height: 40px;
  width: 40px;
}

.form-overlay {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.add-notification-form__inputs {
  display: flex;
  flex-direction: row; /* Align elements vertically */
  justify-content: center; /* Center elements vertically */
  align-items: center; /* Center elements horizontally */
  gap: 20px;
  width: 100%;
}

.add-notification-form-whole{
  margin-top: 20px;
  display: grid;
  gap: 20px;
  width: 100%;
}

.add-notification-form__inputs label{
  font-weight: 500;
}

.add-notification-form__input {
  width: 100%; /* Make input take full width */
  max-width: 400px; /* Optional: Set max-width to avoid too large inputs */
}

.add-notification-form__input input{
  height: 35px;
  width: 100%;
  border-radius: 5px;
  padding: 0 10px;
  border: 1px solid #969ba5;
  background-color: #131348;
  color: #EAECEF;

  &:focus{
    border: 1px solid #F0B90B
  }
}

.add-notification-form__input input::placeholder{
  color: white;
  //font-weight: bold;
  font-family: "Poppins", sans-serif;
  opacity: 1
}

.add-notification-form__btn{
  width: 100%;
  height: 35px;
  margin: 15px 0;
  border-radius: 8px;
  border: none;
  background-color: #F0B90B;
  color: #181A20;
  font-weight: 500;
  cursor: pointer;
}

.form-content{
  display: flex;
  gap: 20px;
}

.anime-id{
  margin-top: 15px; 
}

.notification-success{
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 15px 25px;
  color: white;
  border-radius: 5px;
  font-weight: bold;
  font-size: 1rem;
  opacity: 0;
  animation: slideIn 0.5s forwards, fadeOut 0.5s 2.5s forwards;
  z-index: 1000;
}

.notification-success.success {
  background-color: #4CAF50; /* Green for success */
}

.notification-success.error {
  background-color: #F44336; /* Red for error */
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  to {
    opacity: 0;
  }
}