// card-skeleton.scss

@keyframes colorTransition {
  0% {
    background-color: #1A1A20; // Initial color
  }
  50% {
    background-color: #2E2E38; // Intermediate color
  }
  100% {
    background-color: #1A1A20; // Final color, same as initial for smooth transition
  }
}

.card-skeleton {
  width: 100%;
  height: 353px; // Adjust based on your actual card size
  background-color: #1A1A20; // Slightly lighter than the page background for contrast
  border-radius: 8px;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px; // Adds space between cards, adjust as necessary
  transition: background-color 0.3s ease;

}

.image-skeleton {
  height: 60%;
  background-color: #24242B; // A bit lighter than the card background for visibility
  border-radius: 4px;
  animation: colorTransition 2s infinite alternate;
  transition: background-color 0.3s ease;
}

.text-skeleton {
  background-color: #2E2E38; // A bit lighter to simulate text areas
  border-radius: 4px;
  height: 20px;
  margin: 10px 0;
  animation: colorTransition 2s infinite alternate-reverse;
  transition: background-color 0.3s ease;
}

.title-skeleton {
  width: 70%; // Adjust according to your title's usual length
}

.subtitle-skeleton {
  width: 50%; // Adjust according to your subtitle's usual length
}

.image-skeleton:hover,
.text-skeleton:hover {
  background-color: #1E1E24; // Darker shade on hover
}