.about-us {
  margin-top: 95px;
  margin-bottom: 35px;
  margin-left: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #cbcccb;
  font-weight: 15px;

    .about-us-content {
      max-width: 800px;
      display: flex;
      flex-direction: column;
      gap: 20px;

      h1 {
        display: flex;
        font-size: 30px;
        color: #ff2626;
        margin-bottom: 20px;
        justify-content: center;
        align-items: center;
        gap: 5px;
      }

      hr{
        border: 2px solid #cbcccb;
      }

      .friends-section{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 20px;

        .friend{
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          transition: all 0.3s ease;

          img{
            width: 130px;
            height: 130px;
            border-radius: 20%;
          }

          p{
            font-size: 18px
          }

          &:hover{
            transform: scale(1.05);
          }
        }
      }
    }

    .anime-image {
      flex-shrink: 0;
      width: 600px;
      height: 600px;
      overflow: hidden;

      img {
        width: 90%;
        height: auto;
      }
    }
  }

  @media (max-width: 1024px){

    .about-us{
      flex-direction: column;
      align-items: center;
      font-size: 12px;
      padding: 25px;
      margin-top: 65px;
      margin-bottom: 55px;
      margin-left: 0;

      .about-us-content {
        max-width: 100%;
        text-align: center;
        font-size: 13px;

        .friends-section{
          display: flex;
          justify-content: center;
          align-items: center;

          .friend{
            img{
              width: 125px;
              height: 125px;
            }

            p{
              font-size: 14px;
              text-align: center;
              align-self: center;
            }

          }
        }
      }
    }



      .about-us-content h1{
        font-size: 28px;
      }

    .anime-image {
      display: none;
    }
  }
.about-us .about-us-content .friends-section .friend p {
  font-size: 18px;
  text-align: center;
}