/* src/components/modal/Modal.scss */

/* Modal container */
.modal-container {
  position: fixed;
  right: -360px; /* Initially hidden to the right of the viewport */
  top: 0;
  height: 100vh; /* Full height of the viewport */
  display: flex;
  align-items: center;
  transition: right 0.3s ease; /* Smooth transition for modal sliding */
  z-index: 1000;
}

.username {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
  display: inline-block;
}


.modal-container.open {
  right: 0; /* Slide into view */
}

/* Arrow button */
.open-modal-arrow {
  padding: 15px; /* Padding adjusted */
  background-color: #ff0000; /* Red background for the arrow */
  color: #e0e0e0;
  border: none;
  border-radius: 15px 0 0 15px; /* Rounded corners on the left side */
  cursor: pointer;
  transition: background-color 0.3s ease, right 0.3s ease; /* Added transition for right property */
  font-size: 24px; /* Font size for the icon */
  position: fixed; /* Fixed position */
  right: 1px; /* Adjusted spacing from the right edge */
  top: 50%; /* Center vertically */
  transform: translateY(-50%); /* Vertical centering */
  z-index: 1001; /* Ensure it’s above the modal */
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto; /* Adjust height to content */
  width: auto; /* Adjust width to content */
}

/* When modal is open, move button to the left */
.open-modal-arrow.open {
  right: 350px; /* Move button to the left when modal is open */
}

/* Styling for hover effect */
.open-modal-arrow:hover {
  background-color: #cc0000; /* Darker red on hover */
}

/* Modal content */
.modal-content {
  background: #1e1e1e;
  padding: 20px; /* Increased padding */
  border-radius: 15px 0 0 15px;
  width: 350px; /* Adjusted width */
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.4); /* Increased shadow */
  height: auto; /* Adjusted height to fit content */
  max-height: 85vh;
  position: relative;
  overflow: auto;
  animation: slideInFromRight 0.3s ease-out;
  border: 1px solid #333;
  background: linear-gradient(135deg, #1f1f1f, #2e2e2e);
  display: flex;
  flex-direction: column;
}

.modal-content .close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 28px;
  color: #e0e0e0;
  cursor: pointer;
}

.modal-content h2 {
  color: #e0e0e0;
  margin-bottom: 5px; /* Increased margin */
  font-size: 22px; /* Increased font size */
  text-align: center;
}

.modal-content a {
  //color: #ff1414;
  margin-bottom: 5px;
  font-size: 15px;
  text-align: center;
}

.modal-info {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Reduced gap */
  overflow-y: auto; /* Allow scrolling if content overflows */
}

/* User info styling */
.user-info {
  display: flex;
  align-items: center;
  border-radius: 8px; /* Smaller border radius */
  padding: 10px; /* Increased padding */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  background: #2e2e2e; /* Default background */
  border: 2px solid transparent; /* Default border */
}

.user-info img {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-right: 15px; /* Margin between image and text */
}

/* Background and border for top users */
.user-info.top-one {
  border: 3px solid gold; /* Gold border for 1st place */
  background: rgba(255, 215, 0, 0.1); /* Light gold background */
  box-shadow: 0 0 10px rgba(255, 215, 0, 0.3); /* Gold shadow */
}

.user-info.top-two {
  border: 3px solid silver; /* Silver border for 2nd place */
  background: rgba(192, 192, 192, 0.1); /* Light silver background */
  box-shadow: 0 0 10px rgba(192, 192, 192, 0.3); /* Silver shadow */
}

.user-info.top-three {
  border: 3px solid #cd7f32; /* Bronze border for 3rd place */
  background: rgba(205, 127, 50, 0.1); /* Light bronze background */
  box-shadow: 0 0 10px rgba(205, 127, 50, 0.3); /* Bronze shadow */
}

/* Rank number styling */
.user-rank {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #333; /* Dark background for the rank */
  color: #fff;
  border-radius: 50%;
  width: 30px; /* Adjusted size */
  height: 30px; /* Adjusted size */
  margin-right: 15px; /* Margin between rank and image */
  font-size: 16px; /* Font size for the rank number */
  font-weight: bold;
}

/* Position info styling */
.position-info {
  background: #ff9800;
  color: #fff;
  border-radius: 8px; /* Smaller border radius */
  padding: 10px; /* Increased padding */
  text-align: center;
  font-weight: bold;
}

.highlight {
  background-color: #81137d;
}

/* Keyframes for modal animation */
@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOutToRight {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}
