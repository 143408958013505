.search-grid {
  display: flex;
  gap: 20px;
  padding: 20px;
}

.filter-panel {
  width: 300px;
  max-height: 535px; /* Ensures it doesn't take up full screen height */
  overflow-y: auto;
  background-color: #131217; /* Dark panel background */
  border: 1px solid #212026;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
}

.filter-panel__header {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
  text-align: center;
  color: #ffffff; /* White text */
}

.filter-panel__content label {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  font-size: 14px;
  color: #cccccc; /* Light grey text */
}

.filter-panel__content select {
  padding: 10px;
  border: 1px solid #444; /* Darker border */
  border-radius: 4px;
  background-color: #29282d; /* Darker input background */
  font-size: 14px;
  color: #e0e0e0; /* Light grey text */
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.filter-panel__content select:focus {
  border-color: #e64444; /* Highlight color */
  box-shadow: 0 4px 6px rgba(230, 68, 68, 0.5);
  outline: none;
}

.filter-panel__content select option:hover {
  background-color: #3a3a5a; /* Darker background on hover */
  color: #de0000; /* Ensure text is visible */
}


.filter-panel button {
  padding: 10px 15px;
  font-size: 14px;
  color: #ffffff; /* White text */
  background-color: #FF0000; /* Button background */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  margin-top: 10px; /* Space above button */
}

.filter-panel button:hover {
  background-color: #c00a0a; /* Darker hover color */
  box-shadow: 0 4px 6px rgba(230, 68, 68, 0.5);
}

.movie-grid {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  padding: 20px;
  background-color: #131217; /* Matches filter panel */
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
}

.movie-grid p {
  text-align: center;
  font-size: 16px;
  color: #999; /* Light grey for no results text */
}

@media (max-width: 1023px) {
  .search-grid {
    flex-direction: column;
    padding: 0;
  }

  .filter-panel {
    width: 100%;
    max-height: 565px;
  }

  .movie-grid {
    display: flex;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
  }
}