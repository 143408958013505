.schedule-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  flex-direction: row;
  gap: 20px;
}

.schedule-item {
  display: flex;
  align-items: center;
  text-decoration: none;
  background-color: #0B0B15;
  width: 400px;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 1px 2px #141b36;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.05);
  }

  .anime-image-schedule img.fixed-size-image {
    min-width: 120px;  /* Fiksna širina */
    max-width: 120px;  /* Fiksna širina */
    min-height: 180px; /* Fiksna visina */
    max-height: 180px; /* Fiksna visina */
    object-fit: cover;  /* Održava proporcionalno smanjenje slike */
    border-radius: 8px;
  }

  .details {
    margin-left: 15px;

    .schedule-title {
      font-size: 18px;
      font-weight: bold;
    }

    .episode {
      font-size: 14px;
      color: #666;
    }

    .date-time {
      font-size: 14px;
      color: #888;
    }

    .countdown {
      font-size: 14px;
      color: #e74c3c;
      font-weight: bold;
    }
  }
}

.translator{
  color: #f81414;
  text-shadow: #ff0000 1px 0 10px;
}

.translator-glow {
  font-weight: bold;
  font-size: 17px;
  color: #0059ff;
  text-shadow: #0033ff 1px 0 10px;
}

.bg-change{
  background-color: #01010e;
  box-shadow: 0 1px 2px #0033ff;
}