.recent-episodes-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .recent-episodes-modal-content {
    background-color: #1f2121;
    padding: 20px;
    border-radius: 10px;
    min-width: 400px;
    max-width: 400px;

    h2 {
      color: #b8bbbe;
      margin-bottom: 20px;
    }

    ul {
      list-style-type: none;
      padding: 0;

      li {
        padding: 1px;

        a {
          display: flex;
          margin-bottom: 10px;
          align-items: center;
          gap: 10px;
          flex-direction: row;
          color: #fff;
          text-decoration: none;
          font-size: 16px;
          transition: 0.2s;
          border-radius: 7px;

          img{
            height: 75px;
            width: auto;
            border-radius: 5px;
          }

          span {
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 35px;
            min-height: 35px;
            max-width: 35px;
            max-height: 35px;
            border: #6c757d 1px solid;
            padding: 8px;
            border-radius: 50%;
            background-color: #3e444b;
            color: #f8f9fa;
          }

          &:hover {
            color: #929ca4;
            font-size: 16.5px;
            background-color: #2d3236;

            img{
              height: 76px;
            }

            span{
              min-width: 36px;
              min-height: 36px;
              max-width: 36px;
              max-height: 36px;
            }
          }
        }
      }
    }

    button {
      background-color: #333;
      color: #fff;
      border: none;
      padding: 10px 20px;
      border-radius: 5px;
      cursor: pointer;
      margin-top: 20px;

      &:hover {
        background-color: #555;
      }
    }
  }
}