@use "../../Mainscreen/scss/" as *;
//@import url('https://fonts.googleapis.com/css2?family=Lexend&display=swap');


.movie-card {
  position: relative;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 160% 50% 0 50%;
  border-radius: $border-radius;
  margin-bottom: 1rem;

  .btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: $black;
    opacity: 0;
    transition: opacity 0.3s ease;
    border-radius: $border-radius;
  }

  img {
    max-height: 600px;
  }

  .episode-number {
    position: absolute;
    bottom: 10px; // Razmak od dna
    right: 10px; // Razmak od desne strane
    background-color: rgba(177, 0, 0, 0.85); // Poluprovidna pozadina
    color: white; // Boja teksta
    padding: 5px 10px; // Razmak oko broja
    border-radius: 5px; // Zaobljeni uglovi
    font-size: 14px; // Veličina fonta
  }

  .partner-icon {
    position: absolute;
    top: 10px;
    left: 10px;
    color: #FFD700; /* Zlatna boja, može se promeniti po želji */
    font-size: 2rem;
    z-index: 1;
    background-color: #000; /* Boja pozadine unutar okvira */
    border: 2px solid #FFD700; /* Boja okvira */
    border-radius: 50%; /* Zaokruženi okvir */
    padding: 5px; /* Razmak između ikone i okvira */
}


  @media (hover: hover) and (pointer: fine) {
    &:hover::before {
      opacity: 0.8;
    }

    &:hover .btn {
      transform: translate(-50%, -50%) scale(1);
    }
  }

  .watch-btn {
    font-size: 15px;
    font-family: 'Poppins', sans-serif;
  }
}

@media only screen and (min-width: 376px) and (max-width: 1023px) {
  .card-link{
    max-width: 130px;
    min-width: 130px;
  }

  .movie-card {
    max-width: 130px;
  }
}

@media (max-width: 376px) {
  .card-link{
    max-width: 100px;
    min-width: 100px;
  }

  .movie-card{
    max-width: 100px;
  }
}