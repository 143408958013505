@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700,800,900');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}
@font-face {
  font-display: swap;
}
body {
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.7;
  background: #eee;
  color: #333;

  scrollbar-width: thin;
  scrollbar-color: #4f4f4f #ececec;
  scrollbar-gutter: both-edges;
}

.form-group,
.form-group label,
.form-group input {
  width: 100%;
  margin-bottom: 0.5rem;
}

.form-group label {
  font-size: 0.8rem;
}

.form-group input {
  padding: 10px 20px;
  border: none;
  border-bottom: 3px solid transparent;
  background-color: #080E1D;
  color: white;
  transition: 0.1s;
  outline-width: 0;
  font-size: 1rem;
  border-radius: 7px;
}

.form-group input:focus{
  background-color: #080E1D;
  color: white;
  border-bottom: #386fd2 solid 3px;
}

.error-message {
  font-family: 'Poppins', sans-serif;
  width: 100%;
  display: inline-block;
  padding: 10px;
  background: #040F2B;
  color: whitesmoke;
  text-align: center;
  margin: 0.5rem 0;
  border-radius: 10px;
}

.success-message {
  font-size: 15px;
  font-family: 'Poppins', sans-serif;
  width: 100%;
  display: inline-block;
  padding: 10px;
  background: #040F2B;
  color: whitesmoke;
  text-align: center;
  margin: 0.5rem 0;
  border-radius: 10px;
}

/* This styles the scrollbar track (the background) */
::-webkit-scrollbar-track {
  background: rgba(27,27,39,1); /* Light gray track background */
  border-radius: 10px;
}

/* This styles the scrollbar thumb (the moving part) */
::-webkit-scrollbar-thumb {
  background: #ff0000; /* Reddish color */
  border-radius: 15px;
  box-shadow: 0 0 7px 8px rgba(255, 0, 0, 0.3019607843);
}

/* This styles the scrollbar itself (width and height) */
::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
  height: 8px; /* Height of the scrollbar (relevant for horizontal scrollbars) */
}

@media (max-width: 480px) {
  .form-group label {
    font-size: 12.5px;
  }

  .form-group input {
    font-size: 12.5px;
  }
}

@media (min-width: 480px) and (max-width: 1024px) {
  .form-group label {
    font-size: 17px;
  }

  .form-group input {
    font-size: 17px;
  }
}