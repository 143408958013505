.form-episodes-overlay {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

.form-episodes-content{
    display: flex;
    gap: 20px;
}

.add-episode-form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 450px;
    color: #EAECEF;
    padding: 20px;
    border-radius: 15px;
    background-color: #09092b;
    border: 2px solid white;
}

.form-synopis{
    margin-bottom: 10px;
}

.add-episode-fields{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 100%;
}

.add-episodes {
    width: 100%;
    max-width: 400px;
}

.add-episodes input{
    height: 40px;
    width: 100%;
    border-radius: 5px;
    padding: 0 10px;
    border: 1px solid #969ba5;
    background-color: #131348;
    color: #EAECEF;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;

    &:focus{
        border: 1px solid #F0B90B
    }
}

.add-episodes input::placeholder{
    color: white;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    opacity: 1
}

.episodes-id{
    font-weight: 500;
}

.episodes-dropdown {
    height: 40px;
    width: 100%;
    border-radius: 5px;
    padding: 0 10px;
    border: 1px solid #969ba5;
    background-color: #131348;
    color: #EAECEF;
    font-family: "Poppins", sans-serif;
    overflow: hidden;
    text-overflow: ellipsis;
    /*appearance: none;*/
}

.episodes-dropdown:focus {
    border: 1px solid #F0B90B;
}

.episodes-dropdown option {
    background-color: #131348;
    color: #EAECEF;
    font-weight: 500;
    font-size: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.add-episodes-btn{
    width: 100%;
    height: 40px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 8px;
    border: none;
    background-color: #F0B90B;
    color: #181A20;
    cursor: pointer;
    transition: 0.3s;
}

.add-episodes-btn:hover{
    color: #EAECEF;
    background-color: #131348;
    border: white 2px solid;
}

.notification-episode-success{
    position: fixed;
    top: 20px;
    right: 20px;
    padding: 15px 25px;
    color: white;
    border-radius: 5px;
    font-weight: bold;
    font-size: 1rem;
    opacity: 0;
    animation: slideIn 0.5s forwards, fadeOut 0.5s 2.5s forwards;
    z-index: 1000;
}

.notification-episode-success.success {
    background-color: #4CAF50;
}

.notification-episode-success.error {
    background-color: #F44336;
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeOut {
    to {
        opacity: 0;
    }
}