.barChartBox{
  width: 100%;
  height: 100%;

  h1{
    font-size: 20px;
    margin-bottom: 20px;
  }

  .chart{
  }
}
.custom-tooltip {
  background-color: #2A3447;
  border: 1px solid #cccccc;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.bar_chart_title{
  justify-self: center;
  text-align: center;
}