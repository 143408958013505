@import "../../Styles/responsive.scss";

.navbar {
  width: 100%;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .logodb {
    display: flex;
    align-items: center;
    font-weight: bold;
    gap: 10px;
    font-size: 25px;
    cursor: pointer;
  }
  .iconsdb {
    display: flex;
    align-items: center;
    gap: 20px;

    .icondb{
      @include sm{
        display: none;
      }
    }

    .notification {
      position: relative;

      span {
        background-color: red;
        color: white;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        position: absolute;
        top: -10px;
        right: -10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
      }
    }

    .user {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      font-size: 25px;
      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }
}