*{
  margin: 0;
  padding: 0;
}

.naruto-shp{
  width: 370px;
  height: auto;
  margin: 0 0 10px 0;
}

.naruto-shp img{
  border-radius: 12px;
}

.episode-screen{
  display: flex;
  margin-bottom: 50px;
  flex-direction: row
}

.left-ep-scr{
  display: flex;
  flex-direction: column;
}

.episode-wrapper{
  display: flex;
  flex-direction: column;
  margin: 100px 0 0 20px;
  gap: 15px;
}

.episode-num{
  margin-left: 30px;
  max-width: 1000px;
  font-size: 28px;
}

.players-control{
  display: flex;
  justify-content: space-between;
  color: #9a9494;
  font-size: 14px;

  .episode-skip{
    display: flex;
    flex-direction: row;
    gap: 15px;
  }

  .ep-inactive{
    cursor: not-allowed;
    color: #5a5656;

    &:hover{
      color: #5a5656;
    }
  }

  .ep-active{

    &:hover{
      color: #d20000;
    }
  }
}



.episode-player{
  border-radius: 12px;
  border-end-start-radius: 0;
  border-end-end-radius: 0;
}

.episode-count{
  display: flex;
  flex-direction: column;
  padding: 15px;
  border-radius: 10px;
  border-start-end-radius: 0;
  border-start-start-radius: 0;
  background: #17171c;
  margin-top: -7px;
  margin-left: 20px;
  width: 1016px;
  gap: 10px;
}

.episode-chose{
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.ep-number-input{
  border: 1px solid whitesmoke;
  background-color: #0D0D12;
  color: white;
  padding: 12px;
  border-radius: 50px;
  width: 20%;
}
.episode-list-container{
  width: 60 * 14px;
  background-color: rgba(7, 9, 12, 0.99);
  padding: 20px;
  border: 1px solid whitesmoke;

  h1{
    margin-bottom: 15px;
  }

  .ep-list-up{
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    button{
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      padding: 12px;
      gap: 4px;
      background-color: #1f1f28;
      color: whitesmoke;
      border-radius: 7px;
      border: 1px solid whitesmoke;
      transition: 0.3s;

      &:hover{
        background-color: #16161c;
        border-color: #969ba5;
        color: #969ba5;
      }
    }
  }
}

.episode-list-ep {
  display: flex;
  gap: 10px;
  width: 100%;
  flex-wrap: wrap;
  max-height: 150px;
  overflow-y: auto;
  padding-right: 10px;
  scrollbar-width: thin;
  scrollbar-color: #0D0D12 #424242;
  scrollbar-arrow-color: transparent;
  scrollbar-face-color: #424242;
  scrollbar-shadow-color: transparent;
  scrollbar-highlight-color: transparent;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #0D0D12;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #424242;
    border-radius: 10px;
  }
}

.ep-number-btn{
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid whitesmoke;
  background-color: #0D0D12;
  color: white;
  width: 45px;
  height: 35px;
  border-radius: 10px;

  &:hover {

    background-color: #d20000;
    color: whitesmoke;
  }
}

.server-list{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 15px;
  border-radius: 10px;
  background: #17171c;
  margin-top: 20px;
  margin-left: 20px;
  width: 1016px;
  gap: 15px;
}

.episode-server{
  padding: 12px 20px;
  border: 1px solid whitesmoke;
  background-color: #0D0D12;
  border-radius: 15px;
  cursor: pointer;
  color: whitesmoke;
  font-weight: bold;
  font-size: 15px;

  &:hover{
    background-color: #a10700;
  }
}

.active-server{
  padding: 12px 20px;
  border: 1px solid whitesmoke;
  background-color: #cb0101;
  border-radius: 15px;
  cursor: pointer;
  color: whitesmoke;
  font-weight: bold;
  font-size: 15px;

  &:hover{
    background-color: #910000;
  }
}

.series-description{
  display: flex;
  align-items: center;
  border: 2px solid whitesmoke;
  background-color: #0D0D12;
  justify-content: space-evenly;
  margin-left: 20px;
  margin-top: 20px;

  padding: 15px;
  border-radius: 15px;
  width: 1016px;
  gap: 20px;

  &-name{
    font-size: 25px;
  }

  &-genres{
    display: flex;
    flex-direction: row;
    gap: 10px;

    &-item{
      padding: 4px 8px;
      border: 2px solid whitesmoke;
      border-radius: 30px;
      font-size: 0.8rem;
      font-weight: 600;
      background-color: #0D0D12;

      &:hover{
        background-color: #d20000;
        color: whitesmoke;
      }
    }
  }

  &-data{
    width: 400px;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 2px solid whitesmoke;
    background-color: #0D0D12;
    border-radius: 15px;
    padding: 10px 10px;
    display: flex;
    flex-direction: row;
    gap: 45px;

    &-row{
      display: flex;
      flex-direction: column;
    }
  }
}

.series-img{
  width: 230px;
  height: 330px;
  border-radius: 10px;
}

.right-ep-screen{
  display: flex;
  flex-direction: column;
  margin-left: 45px;
  margin-top: 170px;

  .mw-sort{
    max-width: 370px;
    display: flex;
    flex-direction: column;
    border: 2.5px solid whitesmoke;
    background-color: rgba(7, 9, 12, 0.99);
    padding: 17px;
    border-radius: 8px;
  }

  .mst-viewed {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 15px;

    h3 {
      justify-content: flex-start;
      align-items: flex-start;
      color: whitesmoke;
    }

    .type-choose {
      margin: 5px;
      display: flex;
      position: relative;
      flex-wrap: wrap;
      border-radius: 0.5rem;
      background-color: #21212a;
      box-sizing: border-box;
      box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.06);
      padding: 0;
      width: 190px;
      font-size: 12px;

      .radio {
        flex: 1 1 auto;
        text-align: center;
      }

      .radio input {
        display: none;
      }

      .radio .date-name {
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        border-radius: 0.5rem;
        border: none;
        padding: 3px 7px;
        color: rgb(138, 154, 178);
        transition: all 0.15s ease-in-out;
      }

      .radio input:checked + .date-name {
        background-color: #151414;
        font-weight: 600;
        color: whitesmoke;
      }
    }
  }

  .views-sort {
    display: flex;
    flex-direction: column;

    .title-views {
      display: flex;
      flex-direction: column;

      .title-sort {
        width: 200px;
        white-space: normal;    // Allows text to wrap on small screens
        display: -webkit-box;   // Required for line-clamp to work
        -webkit-line-clamp: 2;  // Limits text to 2 lines
        -webkit-box-orient: vertical; // Required for line-clamp to work
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .sort-stats {
        display: flex;
        flex-direction: row;
        gap: 10px;
      }

      p {
        color: #969696;
        font-size: 12px;
      }
    }

    li {
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 25px;
      background-color: #151414;
      border-radius: 15px;
      margin-top: 15px;
    }

    img {
      height: 80px;
      width: 60px;
      border-start-start-radius: 15px;
      border-end-start-radius: 15px;
    }
  }

  .tags {
    margin-top: 35px;
    display: flex;
    flex-direction: column;
    border: 2.5px solid whitesmoke;
    background-color: rgba(7, 9, 12, 0.99);
    padding: 17px;
    border-radius: 8px;
    font-size: 14px;
    max-width: 370px;

    h2 {
      font-size: 19px;
    }
  }

  @media only screen and (max-width: 578px) {
    .title-sort {
      white-space: normal; // Allows text to wrap
      display: -webkit-box;
      -webkit-line-clamp: 1; // Limits text to 1 line
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis; // Adds "..." to truncated text
    }
  }

  .naruto-shp{
    max-width: 370px;
  }

  .tags{
    margin-top: 35px;
    display: flex;
    flex-direction: column;
    border: 2.5px solid whitesmoke;
    background-color: rgba(7, 9, 12, 0.99);
    padding: 17px;
    border-radius: 8px;
    font-size: 14px;
    max-width: 370px;

    h2{
      font-size: 19px;
    }
  }
}

@media only screen and (min-width: 1440px) {

}

@media only screen and (min-width: 1024px) {

}

@media only screen and (max-width: 578px) {
  .episode-screen{
    display: flex;
    margin-bottom: 50px;
    flex-direction: column;
    width: 100%;
    justify-self: center;
  }

  .server-list{
    align-self: center;
    justify-self: center;
    width: 85%;
  }
  .episode-server{
    font-size: 13px;
    padding: 11px;
  }
  .active-server{
    font-size: 13px;
    padding: 11px;
  }

  .episode-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    width: 96%;
    margin: 80px 0 0 0;
  }
  .player-holder{
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .episode-num{
    margin-left: 15px;
    flex-direction: column;
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 17px;
  }
  .episode-player{
    width: 90%;
    height: 230px;
  }
  .episode-count-holder{
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .episode-count{
    width: 87%;
    margin: 0;
    font-size: 12px;
  }
  .players-control{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    font-size: 13px;
  }
  .ep-number-input{
    width: 110px;
    height: 40px;
    border-radius: 15px;
  }
  .series-description{
    display: flex;
    flex-direction: column;
    width: 90%;
  }
  .series-img{
    border: 2px solid #eeeeee;
  }
  .series-data{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .series-description-genres-item{
    font-size: 12px;
  }
  .series-description-data{
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 13px;
  }
  .series-desc-text{
    font-size: 12px;
  }
  .right-ep-screen{
    margin-top: 20px;
    margin-left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mw-sort{
    display: flex;
    width: 90%;
    justify-content: center;
    align-items: center;
  }

  .naruto-shp{
    width: 90%;
  }

  .tags{
    display: flex;
    width: 90%;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1016px) {
  .episode-screen{
    display: flex;
    margin-bottom: 50px;
    flex-direction: column;
    width: 100%;
    justify-self: center;
  }

  .server-list{
    align-self: center;
    justify-self: center;
    width: 85%;
  }

  .episode-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 80px 0 0 0;
  }
  .player-holder{
    width: 100%;
    height: 390px;
    display: flex;
    justify-content: center;
  }
  .episode-num{
    width: 100%;
    margin: 0;
    font-size: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .episode-title{
    display: flex;
    width: 1016px;
    max-width: 1016px;
  }
  .episode-player{
    width: 90%;
    height: 100%;
  }
  .episode-count-holder{
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .episode-count{
    width: 90%;
    margin: 0;
  }
  .players-control{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-size: 17px;
    gap: 20px;
  }
  .ep-number-input{
    width: 150px;
    height: 50px;
    font-size: 15px;
    border-radius: 15px;
  }
  .series-description{
    display: flex;
    flex-direction: column;
    width: 90%;
  }
  .series-ads{
    margin-top: 20px;
    display: flex;
    width: 90%;
  }
  .series-img{
    border: 2px solid #eeeeee;
  }
  .series-data{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .series-description-genres-item{
    font-size: 14px;
  }
  .series-description-data{
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 16px;
  }
  .series-desc-text{
    font-size: 13px;
    padding: 10px;
  }
  .right-ep-screen{
    margin-top: 20px;
    margin-left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mw-sort{
    display: flex;
    width: 90%;
    justify-content: center;
    align-items: center;
  }

  .naruto-shp{
    width: 90%;
  }

  .tags{
    display: flex;
    width: 90%;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (min-width: 2560px){
  .episode-num{
    max-width: 1000px;
  }
}