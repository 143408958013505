@use "../../scss/" as *;
@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700,800,900');

.hero-slide {
  margin-bottom: 3rem;
}

.x-close-btn{
  font-family: 'Poppins', sans-serif;
  font-style: normal;
}
.hero-slide__item {
  padding: 9rem 0;
  width: 100%;
  position: relative;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;


  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include overlay();
  }

  &::after {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 100px;
    background-image: linear-gradient(to top, $body-bg, rgba($black, 0));
  }

  &__content {
    @include flex(center, center);
    position: relative;

    &__info {
      width: 55%;
      padding: 0 3rem;
      position: relative;

      @include tablet {
        width: 100%;
      }

      & > * ~ * {
        margin-top: 3rem;
      }

      .title {
        font-size: 5rem;
        font-weight: 700;
        line-height: 1;

        @include tablet {
          font-size: 4rem;
        }
      }

      .overview {
        font-weight: 700;
      }

      .hero-btns > * ~ * {
        margin-left: 1rem;
      }

      .hero-btns,
      .title,
      .overview {
        opacity: 0;
        transform: translateY(-100px);
        transition: transform 0.5s ease, opacity 0.5s ease;
      }
    }

    &__poster {
      flex: 1;
      @include flex(center, flex-start);
      position: relative;

      img {
        width: 400px;
        border-radius: $border-radius;
        box-shadow: $box-shadow;

        transform: scale(0);
        transition: transform 0.7s ease;
      }

      @include tablet {
        display: none;
      }
    }
  }

  &.active > &__content > &__content__poster {
    img {
      transform: scale(1);
    }
  }

  &.active > &__content > &__content__info {
    .hero-btns,
    .title,
    .overview {
      opacity: 1;
      transform: translateY(0);
    }

    .title {
      transition-delay: 0.3s, 0.3s;
    }

    .overview {
      transition-delay: 0.6s, 0.6s;
    }

    .hero-btns {
      transition-delay: 0.9s, 0.9s;
    }
  }

  .hero-btns{
    .hero-btn{
      padding: 10px 20px;
      cursor: pointer;
      width: 100%;
      font-size: 1rem;
    }
  }

}

@media only screen and (max-width: 578px) {
  .hero-slide__item__content__info .title{
    font-size: 2rem;
  }
  .hero-slide__item__content__info .title{
    white-space: normal;    // Allows text to wrap on small screens
    display: -webkit-box;   // Required for line-clamp to work
    -webkit-line-clamp: 2;  // Limits text to 2 lines
    -webkit-box-orient: vertical; // Required for line-clamp to work
    overflow: hidden;
    text-overflow: ellipsis; // Adds "..." at the end of the truncated text

  }
  .hero-slide__item__content__info .overview{
    white-space: normal;    // Allows text to wrap on small screens
    display: -webkit-box;   // Required for line-clamp to work
    -webkit-line-clamp: 6;  // Limits text to 2 lines
    -webkit-box-orient: vertical; // Required for line-clamp to work
    overflow: hidden;
    text-overflow: ellipsis; // Adds "..." at the end of the truncated text

  }
}
@media only screen and (min-width: 768px) {
  .hero-slide__item__content__info .title{
    font-size: 2rem;
  }
  .hero-slide__item__content__info .title{
    white-space: normal;    // Allows text to wrap on small screens
    display: -webkit-box;   // Required for line-clamp to work
    -webkit-line-clamp: 2;  // Limits text to 2 lines
    -webkit-box-orient: vertical; // Required for line-clamp to work
    overflow: hidden;
    text-overflow: ellipsis; // Adds "..." at the end of the truncated text

  }
  .hero-slide__item__content__info .overview{
    white-space: normal;    // Allows text to wrap on small screens
    display: -webkit-box;   // Required for line-clamp to work
    -webkit-line-clamp: 6;  // Limits text to 2 lines
    -webkit-box-orient: vertical; // Required for line-clamp to work
    overflow: hidden;
    text-overflow: ellipsis; // Adds "..." at the end of the truncated text

  }
}

@media only screen and (min-width: 1024px) {
  .hero-slide__item__content__info .title{
    font-size: 3rem;
  }
  .hero-slide__item__content__info .title{
    white-space: normal;    // Allows text to wrap on small screens
    display: -webkit-box;   // Required for line-clamp to work
    -webkit-line-clamp: 2;  // Limits text to 2 lines
    -webkit-box-orient: vertical; // Required for line-clamp to work
    overflow: hidden;
    text-overflow: ellipsis; // Adds "..." at the end of the truncated text

  }
  .hero-slide__item__content__info .overview{
    white-space: normal;    // Allows text to wrap on small screens
    display: -webkit-box;   // Required for line-clamp to work
    -webkit-line-clamp: 6;  // Limits text to 2 lines
    -webkit-box-orient: vertical; // Required for line-clamp to work
    overflow: hidden;
    text-overflow: ellipsis; // Adds "..." at the end of the truncated text

  }
}

@media only screen and (min-width: 1440px) {
  .hero-slide__item__content__info .title{
    font-size: 3.5rem;
  }
  .hero-slide__item__content__info .title{
    white-space: normal;    // Allows text to wrap on small screens
    display: -webkit-box;   // Required for line-clamp to work
    -webkit-line-clamp: 2;  // Limits text to 2 lines
    -webkit-box-orient: vertical; // Required for line-clamp to work
    overflow: hidden;
    text-overflow: ellipsis; // Adds "..." at the end of the truncated text

  }
  .hero-slide__item__content__info .overview{
    white-space: normal;    // Allows text to wrap on small screens
    display: -webkit-box;   // Required for line-clamp to work
    -webkit-line-clamp: 6;  // Limits text to 2 lines
    -webkit-box-orient: vertical; // Required for line-clamp to work
    overflow: hidden;
    text-overflow: ellipsis; // Adds "..." at the end of the truncated text

  }
}