@use "../../scss/" as *;

@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700,800,900');

.header-search-grid{
  display: flex;
  flex-direction: column;
}

.user{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.social{
  display: flex;
  font-size: 33px;

  a{
    display: flex;
    margin-left: 15px;
    justify-content: flex-end;
    align-items: flex-end;
  }
}

.dropdown-menu{
  width: 300px;
  display: flex;
  margin-top: 5px;
  position: absolute;
  padding: 15px 13px;
  border-radius: 12px;
  border: 3px solid rgba(255, 255, 255, .1);
  background-color: rgba(36,36,40,.95);
  cursor: pointer;
  font-size: 16px;
  flex-direction: column;

  .username{
    color: #ff2828;
  }

  .email{
    font-size: 14px;
    color: #FFFFFFB2;
    margin-bottom: 15px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .log-out{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 5px;

    &:hover{
      color: #ff2828;
    }
  }

  .options {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    flex-direction: column;

    button, a {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      font-size: 15px;
      font-weight: normal;
      border: none;
      background-color: #FFFFFF19;
      color: whitesmoke;
      border-radius: 25px;
      width: 95%;
      padding: 10px;
      margin-bottom: 5px;

      &:hover {
        background-color: #FFFFFF33;
        color: #ff2828;
      }
    }
  }
}

.logo {
  font-size: 34.5px;
  font-weight: 650;
  font-family: 'Poppins', sans-serif;

  @include flex(center, row);

  img {
    margin-right: 5px;
    width: 40px;
  }

  .bf{
    display: flex;
    flex-direction: row;
    letter-spacing: -1.2px;

    .flix{
      color: red;
    }
  }
}

.main_header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 65px;
  z-index: 99;
  transition: height 0.3s ease, background-color 0.3s ease;

  &.shrink {
    height: 70px;
    background-color: #0D0D12;
  }

  &__wrap {
    @include flex(center, space-between);
    height: 100%;
    padding: 0 2rem;
    justify-content: space-between;
    align-items: center;
  }

  &__nav {
    @include flex(center, unset);

    & > * ~ * {
      margin-left: 2rem;
    }
  }

  //burger

  .nav-btn {
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    color: var(--textColor);
    visibility: hidden;
    opacity: 0;
    font-size: 30px;
  }

  .src-loupe{
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    color: var(--textColor);
    display: none;
    opacity: 0;
    font-size: 30px;
  }

  .nav-content{
    display: flex;
    flex-direction: row-reverse;
    gap: 20px;
  }

  .social-menu{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    gap: 5px;
  }

  .src-icon{
    height: 25px;
    width: 25px;
    cursor: pointer;
  }

  .login-header-btn{
    min-width: 125px;
    min-height: 50px;
    border: none;
    border-radius: 25px;
    background-color: #ff0000;
    color: white;
    cursor: pointer;
    padding: 9px;
    transition: all 0.2s;
    font-weight: 550;
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;

    &:hover{
      background: #d70404;
      color: white;
      font-size: 16px;
      box-shadow: #8C0000FF 0px 7px 29px 0px;
      border: 3px solid #8C0000FF;
    }
  }
  .user{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .nav-user{
    font-size: 20px;

    .userInf{
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5em;
      cursor: pointer;
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 768px){

    .main_header__wrap {
      justify-content: space-between;
      padding: 0 1.5em;
    }

    .social{
      display: none;
    }

    .nav-btn{
      margin-top: 5px;
        visibility: visible;
        opacity: 1;
      }

    .logo{
      font-size: 25px;
      justify-content: center;
      align-items: center;

      img{
        width: 30px;
      }
    }

      nav{
        position: fixed;
        top: 0;
        left: -100%;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: 1.5rem;
        background-color: rgba(7, 15, 43, 0.99);
        z-index: 9999;
        transition: all .4s ease;
      }

    .nav-content{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 20px;
    }

      .responsive_nav {
        transform: translateX(100%);
      }

      nav .nav-close-btn {
        position: absolute;
        top: 1rem;
        left: 2rem;
      }

    .mosc{
      display: none;
      opacity: 0;
      visibility: hidden;
    }

    .src-loupe{
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 1;
    }

    .movie-search{
      font-size: 45px;
      width: 50px;
    }

    .movie-search-phone{
      display: flex;
      margin-top: 5px;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1024px){

    .main_header__wrap {
      justify-content: space-between;
      padding: 0 1.5em;
    }

    .social{
      display: none;
    }

    .nav-btn{
      margin-top: 5px;
      visibility: visible;
      opacity: 1;
    }

    .logo{
      font-size: 25px;
      justify-content: center;
      align-items: center;

      img{
        width: 35px;
      }
    }

    nav{
      position: fixed;
      top: 0;
      left: -100%;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      gap: 1.5rem;
      background-color: rgba(7, 15, 43, 0.99);
      z-index: 9999;
      transition: all .4s ease;
    }

    .nav-content{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 20px;
    }

    .responsive_nav {
      transform: translateX(100%);
    }

    nav .nav-close-btn {
      position: absolute;
      top: 1rem;
      left: 2rem;
    }

    .mosc{
      display: none;
      opacity: 0;
      visibility: hidden;
    }

    .src-loupe{
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 1;
    }

    .movie-search{
      font-size: 45px;
      width: 50px;
    }

    .movie-search-phone{
      left: 28%;
      display: flex;
      margin-top: 5px;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  }


}

@media only screen and (max-width: 320px) {
  .logo{
    font-size: 20px;
    justify-content: center;
    align-items: center;

    img{
      width: 25px;
    }
  }

  .social{
    display: none;
  }

  .social-menu{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    gap: 5px;
  }
}