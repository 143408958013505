@import "../../Styles/variables";
@import "../../Styles/responsive";

.home {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: minmax(180px, auto);
  grid-auto-flow: dense;

  @include xl{
    grid-template-columns: repeat(3, 1fr);
  }
  @include lg{
    grid-template-columns: repeat(2, 1fr);
  }
  @include md {
    grid-template-columns: repeat(1, 1fr);
  }
  @include sm {
    grid-auto-rows: minmax(120px, auto);
  }

  .box {
    padding: 10px 20px 10px 20px;
    border-radius: 10px;
    //border: 2px solid $soft-bg;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0B0B15;
  }

  .box1 {
    grid-column: span 1;
    grid-row: span 3;
  }

  .box4 {
    grid-column: span 1;
    grid-row: span 3;
  }
  .box7 {
    grid-column: span 2;
    grid-row: span 2;

    @include md {
      display: none;
    }
  }
}
.box_info_div{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 19px;
}
.title_dashboard{
  display: flex;
  justify-content: center;
  align-items: center;
}